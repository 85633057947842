import { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Users, 
  FileText, 
  AlertTriangle, 
  Settings, 
  BarChart, 
  LogOut 
} from 'lucide-react';

function AdminLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    { icon: LayoutDashboard, label: 'Dashboard', path: '/admin' },
    { icon: AlertTriangle, label: 'Reports', path: '/admin/reports' },
    { icon: FileText, label: 'Content', path: '/admin/content' },
    { icon: Users, label: 'Users', path: '/admin/users' },
    { icon: BarChart, label: 'Analytics', path: '/admin/analytics' },
    { icon: Settings, label: 'Settings', path: '/admin/settings' },
  ];

  return (
    <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
      {/* Sidebar */}
      <div className={`bg-white dark:bg-gray-800 shadow-lg ${
        collapsed ? 'w-20' : 'w-64'
      } transition-all duration-300`}>
        <div className="flex flex-col h-full">
          {/* Logo */}
          <div className="p-4 border-b border-gray-200 dark:border-gray-700">
            <Link to="/admin" className="flex items-center">
              <img src="/logo.svg" alt="WeqayaOnline" className="h-8 w-8" />
              {!collapsed && (
                <span className="ml-2 text-lg font-bold text-emerald-600">
                  Admin Panel
                </span>
              )}
            </Link>
          </div>

          {/* Navigation */}
          <nav className="flex-1 overflow-y-auto py-4">
            {menuItems.map(({ icon: Icon, label, path }) => (
              <Link
                key={path}
                to={path}
                className="flex items-center px-4 py-3 text-gray-700 dark:text-gray-200 hover:bg-emerald-50 dark:hover:bg-gray-700"
              >
                <Icon className="h-5 w-5" />
                {!collapsed && <span className="ml-3">{label}</span>}
              </Link>
            ))}
          </nav>

          {/* Footer */}
          <div className="border-t border-gray-200 dark:border-gray-700 p-4">
            <button
              onClick={() => navigate('/')}
              className="flex items-center w-full text-gray-700 dark:text-gray-200 hover:text-red-600"
            >
              <LogOut className="h-5 w-5" />
              {!collapsed && <span className="ml-3">Exit Admin</span>}
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-x-hidden overflow-y-auto">
        <div className="container mx-auto px-6 py-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AdminLayout; 