import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { SupabaseProvider } from './context/SupabaseContext';
import { AuthProvider } from './context/AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import { useAnalytics } from './hooks/useAnalytics';

// Import components
import Layout from './components/Layout';
import Home from './pages/Home';
import ScamChecker from './pages/ScamChecker';
import ScamLibrary from './pages/ScamLibrary';
import ScamDetail from './pages/ScamDetail';
import Blog from './pages/Blog';
import ScamReporting from './pages/ScamReporting';
import SafetyTips from './pages/SafetyTips';
import AdminLayout from './components/AdminLayout';
import AdminLogin from './pages/admin/Login';
import Dashboard from './pages/admin/Dashboard';
import AdminReports from './pages/admin/Reports';
import AdminContent from './pages/admin/Content';
import AdminUsers from './pages/admin/Users';
import AdminAnalytics from './pages/admin/Analytics';
import AdminSettings from './pages/admin/Settings';
import Error404 from './pages/Error404';
import Error500 from './pages/Error500';

// Create Analytics Wrapper Component
function AnalyticsWrapper({ children }) {
  useAnalytics(); // Track page views

  return children;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <AnalyticsWrapper><Layout /></AnalyticsWrapper>,
    errorElement: <Error404 />,
    children: [
      { index: true, element: <Home /> },
      { path: "scam-checker", element: <ScamChecker /> },
      { path: "scam-library", element: <ScamLibrary /> },
      { path: "scam-library/:id", element: <ScamDetail /> },
      { path: "blog", element: <Blog /> },
      { path: "report-scam", element: <ScamReporting /> },
      { path: "safety-tips", element: <SafetyTips /> },
      { path: "500", element: <Error500 /> },
      { path: "*", element: <Error404 /> },
    ],
  },
  {
    path: "admin",
    children: [
      { path: "login", element: <AdminLogin /> },
      {
        path: "",
        element: <AdminLayout />,
        children: [
          { index: true, element: <Dashboard /> },
          { path: "reports", element: <AdminReports /> },
          { path: "content", element: <AdminContent /> },
          { path: "users", element: <AdminUsers /> },
          { path: "analytics", element: <AdminAnalytics /> },
          { path: "settings", element: <AdminSettings /> },
        ],
      },
    ],
  },
], {
  future: {
    v7_startTransition: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_skipActionErrorRevalidation: true
  }
});

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <SupabaseProvider>
          <ThemeProvider>
            <RouterProvider router={router} />
          </ThemeProvider>
        </SupabaseProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App; 