import { useState } from 'react';

function Settings() {
  return (
    <div>
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
        Admin Settings
      </h1>
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6">
        <p>Settings panel coming soon...</p>
      </div>
    </div>
  );
}

export default Settings; 