import { useState, useCallback } from 'react';
import { useSupabase } from '../context/SupabaseContext';
import { analyzeScam } from '../services/gemini';
import SEO from '../components/SEO';
import { debounce } from 'lodash';
import { 
  Shield, AlertTriangle, MessageSquare, Mail, Globe, Database, 
  CheckCircle, XCircle, ArrowRight, Loader2, Lock
} from 'lucide-react';

function ScamChecker() {
  const { supabase } = useSupabase();
  const [checkType, setCheckType] = useState('message');
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [remainingChecks, setRemainingChecks] = useState(null);

  const checkTypes = {
    message: {
      icon: MessageSquare,
      title: 'Message Check',
      placeholder: 'Paste the suspicious message here...',
      description: 'Verify SMS, WhatsApp, or social media messages',
      bgColor: 'bg-emerald-600'
    },
    email: {
      icon: Mail,
      title: 'Email Analysis',
      placeholder: 'Paste the suspicious email content here...',
      description: 'Detect phishing and fraudulent emails',
      bgColor: 'bg-emerald-600'
    },
    website: {
      icon: Globe,
      title: 'Website Verification',
      placeholder: 'Enter the website URL...',
      description: 'Check for fake or malicious websites',
      bgColor: 'bg-emerald-600'
    },
  };

  // Rate limiting check
  const checkRateLimit = async (userIp) => {
    const now = new Date();
    const hourAgo = new Date(now.getTime() - 60 * 60 * 1000);

    const { data: checks, error } = await supabase
      .from('scam_checks')
      .select('created_at')
      .eq('user_ip', userIp)
      .gte('created_at', hourAgo.toISOString());

    if (error) throw error;

    const hourlyLimit = 10; // Adjust this number as needed
    const remaining = hourlyLimit - (checks?.length || 0);
    setRemainingChecks(remaining);

    return remaining > 0;
  };

  // Get user IP
  const getUserIp = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Error getting IP:', error);
      throw new Error('Could not verify user identity');
    }
  };

  // Input validation
  const validateInput = (text) => {
    if (!text.trim()) {
      throw new Error('Please enter some content to check');
    }
    if (text.length > 5000) {
      throw new Error('Content is too long. Please limit to 5000 characters');
    }
    // Add more validation as needed
  };

  // Debounced check function
  const debouncedCheck = useCallback(
    debounce(async (input, type) => {
      try {
        const userIp = await getUserIp();
        const canProceed = await checkRateLimit(userIp);

        if (!canProceed) {
          throw new Error(`Rate limit exceeded. Please try again in an hour. (${remainingChecks} checks remaining)`);
        }

        validateInput(input);
        
        const analysis = await analyzeScam(input, type);
        
        // Log the check
        await supabase.from('scam_checks').insert({
          type,
          content: input,
          result: analysis.isSafe ? 'safe' : 'scam',
          confidence: analysis.confidence,
          scam_type: analysis.scamType,
          reasons: analysis.reasons,
          recommendation: analysis.recommendation,
          user_ip: userIp,
          created_at: new Date().toISOString()
        });

        setResult(analysis);
        setError(null);
      } catch (error) {
        console.error('Error:', error);
        setError(error.message);
        setResult(null);
      } finally {
        setLoading(false);
      }
    }, 1000), // 1 second delay
    []
  );

  const handleCheck = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResult(null);
    
    debouncedCheck(input, checkType);
  };

  const ResultSection = ({ result }) => (
    <div className="space-y-6 animate-fadeIn">
      {/* Result Header */}
      <div className={`bg-gradient-to-br ${
        result.isSafe 
          ? 'from-green-50 to-emerald-100 dark:from-green-900/30 dark:to-emerald-900/30' 
          : 'from-red-50 to-red-100 dark:from-red-900/30 dark:to-red-900/30'
        } rounded-2xl p-8 shadow-lg`}
      >
        <div className="flex items-center mb-4">
          <div className={`p-3 rounded-full ${
            result.isSafe 
              ? 'bg-green-200 dark:bg-green-800' 
              : 'bg-red-200 dark:bg-red-800'
          }`}>
            {result.isSafe ? (
              <CheckCircle className="h-8 w-8 text-green-700 dark:text-green-300" />
            ) : (
              <XCircle className="h-8 w-8 text-red-700 dark:text-red-300" />
            )}
          </div>
          <div className="ml-4">
            <h3 className={`text-2xl font-bold ${
              result.isSafe ? 'text-green-800 dark:text-green-200' : 'text-red-800 dark:text-red-200'
            }`}>
              {result.isSafe ? 'Content Appears Safe' : 'Potential Scam Detected'}
            </h3>
            {result.scamType && !result.isSafe && (
              <p className="text-red-600 dark:text-red-300 mt-1">
                Type: {result.scamType}
              </p>
            )}
          </div>
        </div>

        {/* Confidence Score */}
        <div className="space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-gray-600 dark:text-gray-300">Confidence Score</span>
            <span className={`font-medium ${
              result.isSafe ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'
            }`}>
              {result.confidence}%
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div 
              className={`h-2.5 rounded-full transition-all duration-1000 ${
                result.isSafe ? 'bg-green-500' : 'bg-red-500'
              }`}
              style={{ width: `${result.confidence}%` }}
            ></div>
          </div>
        </div>
      </div>

      {/* Analysis Details */}
      <div className="grid gap-6 md:grid-cols-2">
        {/* Reasons Section */}
        {!result.isSafe && (
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
            <h4 className="text-lg font-semibold mb-4 flex items-center">
              <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
              Warning Signs Detected
            </h4>
            <ul className="space-y-3">
              {result.reasons.map((reason, index) => (
                <li key={index} className="flex items-start text-gray-600 dark:text-gray-300">
                  <div className="p-1 bg-red-100 dark:bg-red-900/30 rounded-full mr-3 mt-0.5">
                    <AlertTriangle className="h-4 w-4 text-red-500" />
                  </div>
                  <span>{reason}</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Recommendation Section */}
        <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
          <h4 className="text-lg font-semibold mb-4 flex items-center">
            <Shield className="h-5 w-5 text-emerald-500 mr-2" />
            Recommended Action
          </h4>
          <p className="text-gray-600 dark:text-gray-300">
            {result.recommendation}
          </p>
        </div>
      </div>

      {/* Additional Info */}
      <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center text-gray-600 dark:text-gray-300">
            <Database className="h-5 w-5 mr-2" />
            <span>Similar scams reported in UAE: {result.similarScams}</span>
          </div>
          <button 
            onClick={() => window.location.href = '/report-scam'}
            className="flex items-center text-emerald-600 hover:text-emerald-700"
          >
            Report a Scam <ArrowRight className="h-4 w-4 ml-1" />
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SEO 
        title="AI-Powered Scam Checker"
        description="Instantly verify suspicious messages, emails, and websites using advanced AI technology. Protect yourself from scams in UAE."
        keywords="scam detector UAE, fraud checker Dubai, message verification Abu Dhabi"
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-12">
          <div className="inline-block p-3 bg-emerald-100 dark:bg-emerald-900/30 rounded-2xl mb-4">
            <Shield className="h-12 w-12 text-emerald-600" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            AI-Powered Scam Detection
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Verify suspicious content using our advanced AI technology trained on UAE-specific scams
          </p>
        </div>

        {/* Check Type Selector - Updated Design */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {Object.entries(checkTypes).map(([type, { icon: Icon, title, description }]) => (
            <button
              key={type}
              onClick={() => setCheckType(type)}
              className={`relative p-6 rounded-xl text-left transition-all duration-300 ${
                checkType === type
                  ? 'bg-emerald-600 text-white shadow-lg'
                  : 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 border border-gray-200 dark:border-gray-700'
              }`}
            >
              <div className={`p-3 rounded-xl inline-block mb-4 ${
                checkType === type 
                  ? 'bg-white/10' 
                  : 'bg-gray-100 dark:bg-gray-700'
              }`}>
                <Icon className={`h-6 w-6 ${
                  checkType === type ? 'text-white' : 'text-emerald-600 dark:text-emerald-500'
                }`} />
              </div>
              <h3 className={`text-lg font-semibold mb-2 ${
                checkType === type ? 'text-white' : 'text-gray-900 dark:text-white'
              }`}>
                {title}
              </h3>
              <p className={`text-sm ${
                checkType === type 
                  ? 'text-white/90' 
                  : 'text-gray-600 dark:text-gray-400'
              }`}>
                {description}
              </p>
            </button>
          ))}
        </div>

        {/* Input Form - Updated Design */}
        <form onSubmit={handleCheck} className="mb-12">
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 border border-gray-200 dark:border-gray-700">
            <div className="relative">
              <textarea
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={checkTypes[checkType].placeholder}
                rows="6"
                className="w-full p-4 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 dark:bg-gray-700 dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
                required
              />
              <div className="absolute bottom-4 right-4 text-gray-400 dark:text-gray-500">
                <Lock className="h-5 w-5" />
              </div>
            </div>
            <button
              type="submit"
              disabled={loading}
              className={`mt-4 w-full ${checkTypes[checkType].bgColor} text-white py-4 rounded-xl font-semibold
                transition-all duration-300 ${
                  loading ? 'opacity-75 cursor-not-allowed' : 'hover:opacity-90 hover:shadow-lg'
                }`}
            >
              {loading ? (
                <span className="flex items-center justify-center">
                  <Loader2 className="h-5 w-5 mr-2 animate-spin" />
                  Analyzing Content...
                </span>
              ) : (
                'Check Now'
              )}
            </button>
          </div>
        </form>

        {/* Results */}
        {result && <ResultSection result={result} />}

        {/* Tips Section */}
        <div className="bg-gradient-to-br from-emerald-50 to-emerald-100 dark:from-gray-800 dark:to-gray-700 rounded-2xl p-8 mt-12">
          <h3 className="text-xl font-semibold mb-6 text-emerald-800 dark:text-emerald-400 flex items-center">
            <Shield className="h-6 w-6 mr-2" />
            Quick Safety Tips
          </h3>
          <div className="grid md:grid-cols-2 gap-6">
            {[
              'Never share OTP codes or banking credentials',
              'Be cautious of urgent requests for money transfers',
              'Verify official communications through proper channels',
              'Report suspicious activities to UAE authorities'
            ].map((tip, index) => (
              <div 
                key={index}
                className="flex items-start bg-white/50 dark:bg-gray-800/50 p-4 rounded-xl"
              >
                <CheckCircle className="h-5 w-5 text-emerald-600 mr-3 flex-shrink-0 mt-0.5" />
                <span className="text-emerald-900 dark:text-emerald-200">{tip}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Add rate limit info */}
        {remainingChecks !== null && (
          <div className="text-center text-sm text-gray-600 dark:text-gray-400 mb-4">
            {remainingChecks} checks remaining this hour
          </div>
        )}

        {error && (
          <div className="mb-4 p-4 bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 rounded-lg">
            {error}
          </div>
        )}
      </div>
    </>
  );
}

export default ScamChecker; 