import { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSupabase } from '../context/SupabaseContext';
import SEO from '../components/SEO';
import { 
  Shield, 
  AlertTriangle, 
  Share2, 
  Calendar, 
  Tag, 
  ExternalLink,
  MessageSquare,
  Twitter,
  Linkedin,
  Share,
  ChevronRight,
  Flag,
  Eye,
  ThumbsUp,
  Clock,
  UserCheck,
  Users
} from 'lucide-react';

const formatDate = (dateString) => {
  if (!dateString) return 'Not available';
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date) 
    ? date.toLocaleDateString('en-AE', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    : 'Not available';
};

function ScamDetail() {
  const { id } = useParams();
  const { supabase } = useSupabase();
  const [scam, setScam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [relatedScams, setRelatedScams] = useState([]);
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [interactions, setInteractions] = useState({
    views: 0,
    helpful: 0,
    experienced: 0,
    knowSomeone: 0
  });
  const [userInteractions, setUserInteractions] = useState({
    helpful: false,
    experienced: false,
    knowSomeone: false
  });
  const [userIp, setUserIp] = useState(null);
  const [animatingCounts, setAnimatingCounts] = useState({});

  useEffect(() => {
    fetchUserIp();
    fetchScamDetails();
    if (id) {
      recordView();
      fetchInteractions();
    }
  }, [id]);

  async function fetchScamDetails() {
    try {
      setLoading(true);
      const { data: scamData, error } = await supabase
        .from('scams')
        .select(`
          *,
          category:category_id (
            name,
            description
          )
        `)
        .eq('id', id)
        .single();

      if (error) throw error;
      setScam(scamData);

      // Ensure stats record exists and fetch it
      await supabase.rpc('ensure_scam_stats', { p_scam_id: id });
      
      const { data: statsData } = await supabase
        .from('scam_stats')
        .select('views, reports, helpful')
        .eq('scam_id', id)
        .single();

      if (statsData) {
        setInteractions(statsData);
      } else {
        // Set default values if no stats found
        setInteractions({
          views: 0,
          reports: 0,
          helpful: 0
        });
      }

      // Fetch related scams from same category
      if (scamData) {
        const { data: related } = await supabase
          .from('scams')
          .select(`
            id,
            title,
            warning_level,
            category:category_id (name),
            created_at,
            reports_count
          `)
          .eq('category_id', scamData.category_id)
          .neq('id', id)
          .order('created_at', { ascending: false })
          .limit(3);

        setRelatedScams(related || []);
      }
    } catch (error) {
      console.error('Error fetching scam details:', error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchUserIp() {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      setUserIp(data.ip);
    } catch (error) {
      console.error('Error fetching IP:', error);
    }
  }

  async function fetchInteractions() {
    try {
      const { data, error } = await supabase
        .from('scam_interactions')
        .select('interaction_type')
        .eq('scam_id', id);

      if (error) throw error;

      // Count interactions
      const counts = data.reduce((acc, { interaction_type }) => {
        acc[interaction_type] = (acc[interaction_type] || 0) + 1;
        return acc;
      }, {});

      setInteractions({
        views: counts.view || 0,
        helpful: counts.helpful || 0,
        experienced: counts.experienced || 0,
        knowSomeone: counts.know_someone || 0
      });

      // Check user's previous interactions if IP is available
      if (userIp) {
        const { data: userInteractionData } = await supabase
          .from('scam_interactions')
          .select('interaction_type')
          .eq('scam_id', id)
          .eq('user_ip', userIp);

        if (userInteractionData) {
          setUserInteractions({
            helpful: userInteractionData.some(i => i.interaction_type === 'helpful'),
            experienced: userInteractionData.some(i => i.interaction_type === 'experienced'),
            knowSomeone: userInteractionData.some(i => i.interaction_type === 'know_someone')
          });
        }
      }
    } catch (error) {
      console.error('Error fetching interactions:', error);
    }
  }

  async function recordView() {
    if (!userIp) return;
    try {
      await supabase.rpc('increment_scam_interaction', {
        p_scam_id: id,
        p_user_ip: userIp,
        p_interaction_type: 'view'
      });
    } catch (error) {
      console.error('Error recording view:', error);
    }
  }

  async function handleInteraction(type) {
    if (!userIp || userInteractions[type]) return;
    
    try {
      const { data, error } = await supabase.rpc('handle_scam_interaction', {
        p_scam_id: id,
        p_user_ip: userIp,
        p_interaction_type: type
      });

      if (error) throw error;

      // Update local state
      setUserInteractions(prev => ({
        ...prev,
        [type]: true
      }));
      setInteractions(prev => ({
        ...prev,
        [type]: data
      }));

      // Trigger animation
      animateCount(type, data);
    } catch (error) {
      console.error('Error recording interaction:', error);
    }
  }

  const getWarningLevelStyle = (level) => {
    const styles = {
      low: 'bg-green-100 text-green-800 border-green-200',
      medium: 'bg-yellow-100 text-yellow-800 border-yellow-200',
      high: 'bg-orange-100 text-orange-800 border-orange-200',
      critical: 'bg-red-100 text-red-800 border-red-200'
    };
    return styles[level] || styles.medium;
  };

  const shareUrls = {
    message: `whatsapp://send?text=${window.location.href}`,
    twitter: `https://twitter.com/intent/tweet?url=${window.location.href}&text=${scam?.title}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${scam?.title}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`
  };

  const schema = scam ? {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": scam.title,
    "description": scam.description,
    "datePublished": scam.created_at,
    "author": {
      "@type": "Organization",
      "name": "WeqayaOnline"
    },
    "publisher": {
      "@type": "Organization",
      "name": "WeqayaOnline",
      "logo": {
        "@type": "ImageObject",
        "url": "https://weqayaonline.com/logo.png"
      }
    }
  } : null;

  // Record view when component mounts
  useEffect(() => {
    if (id && userIp) {
      handleInteraction('view');
    }
  }, [id, userIp]);

  // Function to animate count change
  const animateCount = (type, newCount) => {
    setAnimatingCounts(prev => ({
      ...prev,
      [type]: { count: newCount, show: true }
    }));
    setTimeout(() => {
      setAnimatingCounts(prev => ({
        ...prev,
        [type]: { ...prev[type], show: false }
      }));
    }, 1000);
  };

  // Subscribe to realtime updates
  useEffect(() => {
    if (!id) return;

    const channel = supabase
      .channel(`scam-${id}`)
      .on('postgres_changes', {
        event: 'INSERT',
        schema: 'public',
        table: 'scam_interactions',
        filter: `scam_id=eq.${id}`
      }, (payload) => {
        const { interaction_type } = payload.new;
        setInteractions(prev => ({
          ...prev,
          [interaction_type]: (prev[interaction_type] || 0) + 1
        }));
      })
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [id]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-600"></div>
      </div>
    );
  }

  if (!scam) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-12 text-center">
        <h1 className="text-2xl font-bold mb-4">Scam Not Found</h1>
        <Link to="/scam-library" className="text-emerald-600 hover:underline">
          Return to Scam Library
        </Link>
      </div>
    );
  }

  return (
    <>
      <SEO 
        title={`${scam.title} - UAE Scam Alert`}
        description={`Learn about this scam targeting UAE residents: ${scam.description.substring(0, 150)}...`}
        keywords={`${scam.title}, UAE scam, Dubai fraud, Abu Dhabi scam, ${scam.category?.name} scam`}
        schema={schema}
      />

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Breadcrumb */}
        <nav className="mb-8">
          <ol className="flex items-center space-x-2 text-sm text-gray-500">
            <li><Link to="/" className="hover:text-emerald-600">Home</Link></li>
            <li>/</li>
            <li><Link to="/scam-library" className="hover:text-emerald-600">Scam Library</Link></li>
            <li>/</li>
            <li className="text-gray-900 dark:text-white">{scam.title}</li>
          </ol>
        </nav>

        {/* Stats Bar */}
        <div className="flex items-center justify-between mb-8 bg-gray-50 dark:bg-gray-800 rounded-xl p-4">
          <div className="flex space-x-6">
            <div className="flex items-center text-gray-600 dark:text-gray-400">
              <Eye className="h-5 w-5 mr-2" />
              {interactions.views} views
            </div>
            <div className="flex items-center text-gray-600 dark:text-gray-400">
              <UserCheck className="h-5 w-5 mr-2" />
              {interactions.experienced} experienced
            </div>
            <div className="flex items-center text-gray-600 dark:text-gray-400">
              <Users className="h-5 w-5 mr-2" />
              {interactions.knowSomeone} know someone
            </div>
            <div className="flex items-center text-gray-600 dark:text-gray-400">
              <ThumbsUp className="h-5 w-5 mr-2" />
              {interactions.helpful} found helpful
            </div>
          </div>
          <button
            onClick={() => setShowShareMenu(!showShareMenu)}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full relative"
          >
            <Share2 className="h-5 w-5" />
            {showShareMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg z-10">
                <div className="p-2 space-y-1">
                  {Object.entries(shareUrls).map(([platform, url]) => (
                    <a
                      key={platform}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                    >
                      {platform === 'message' && <MessageSquare className="h-4 w-4 mr-3" />}
                      {platform === 'twitter' && <Twitter className="h-4 w-4 mr-3" />}
                      {platform === 'linkedin' && <Linkedin className="h-4 w-4 mr-3" />}
                      {platform === 'facebook' && <Share className="h-4 w-4 mr-3" />}
                      Share on {platform.charAt(0).toUpperCase() + platform.slice(1)}
                    </a>
                  ))}
                </div>
              </div>
            )}
          </button>
        </div>

        {/* Main Content */}
        <article className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden">
          <div className="p-8">
            {/* Header */}
            <div className="mb-8">
              <div className="flex items-center space-x-4 mb-4">
                <span className={`px-3 py-1 rounded-full text-sm ${getWarningLevelStyle(scam.warning_level)}`}>
                  {scam.warning_level.toUpperCase()} RISK
                </span>
                <span className="text-gray-600 dark:text-gray-400">
                  {scam.category?.name}
                </span>
              </div>
              
              <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                {scam.title}
              </h1>

              <div className="flex items-center text-sm text-gray-500">
                <Calendar className="h-4 w-4 mr-2" />
                {formatDate(scam.created_at)}
                {scam.updated_at && (
                  <>
                    <Clock className="h-4 w-4 ml-4 mr-2" />
                    Last updated {formatDate(scam.updated_at)}
                  </>
                )}
              </div>
            </div>

            {/* Description */}
            <div className="prose dark:prose-invert max-w-none mb-8">
              <p className="text-gray-600 dark:text-gray-300 whitespace-pre-wrap">
                {scam.description}
              </p>
            </div>

            {/* Warning Box */}
            <div className="bg-red-50 dark:bg-red-900/30 border-l-4 border-red-500 p-6 mb-8">
              <div className="flex">
                <AlertTriangle className="h-6 w-6 text-red-500 mr-3 flex-shrink-0" />
                <div>
                  <h3 className="text-lg font-medium text-red-800 dark:text-red-400 mb-2">
                    How to Protect Yourself
                  </h3>
                  <ul className="space-y-2 text-red-700 dark:text-red-300 list-disc list-inside">
                    {scam.protection_steps?.map((step, index) => (
                      <li key={index}>{step}</li>
                    )) || [
                      'Never share sensitive personal information',
                      'Be wary of urgent or threatening messages',
                      'Verify through official channels',
                      'Report suspicious activities to authorities'
                    ]}
                  </ul>
                </div>
              </div>
            </div>

            {/* Interaction Buttons */}
            <div className="flex flex-wrap gap-4 mt-8 border-t border-gray-200 dark:border-gray-700 pt-6">
              {[
                { type: 'helpful', label: 'Found Helpful', activeLabel: 'Found Helpful', icon: ThumbsUp },
                { type: 'experienced', label: 'I Experienced This', activeLabel: 'You Experienced This', icon: UserCheck },
                { type: 'know_someone', label: 'I Know Someone Affected', activeLabel: 'Know Someone Affected', icon: Users }
              ].map(({ type, label, activeLabel, icon: Icon }) => (
                <div key={type} className="relative">
                  <button
                    onClick={() => handleInteraction(type)}
                    disabled={userInteractions[type]}
                    className={`flex items-center px-4 py-2 rounded-lg transition-all duration-300 ${
                      userInteractions[type]
                        ? 'bg-emerald-100 text-emerald-700 dark:bg-emerald-900/30 dark:text-emerald-400'
                        : 'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-300 hover:bg-emerald-50 dark:hover:bg-emerald-900/20'
                    }`}
                  >
                    <Icon className="h-5 w-5 mr-2" />
                    <span>{userInteractions[type] ? activeLabel : label}</span>
                    <span className="ml-2">
                      {interactions[type] || 0}
                    </span>
                  </button>
                  {/* Floating animation */}
                  {animatingCounts[type]?.show && (
                    <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 text-emerald-600 dark:text-emerald-400 font-bold animate-float-up">
                      +1
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </article>

        {/* Related Scams */}
        {relatedScams.length > 0 && (
          <div className="mt-12">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
              Related Scams
            </h2>
            <div className="grid gap-6 md:grid-cols-3">
              {relatedScams.map(relatedScam => (
                <Link
                  key={relatedScam.id}
                  to={`/scam-library/${relatedScam.id}`}
                  className="group bg-white dark:bg-gray-800 rounded-xl p-6 hover:shadow-lg transition-all duration-300"
                >
                  <div className="flex justify-between items-start mb-4">
                    <span className={`px-2 py-1 text-xs rounded-full ${getWarningLevelStyle(relatedScam.warning_level)}`}>
                      {relatedScam.warning_level.toUpperCase()}
                    </span>
                    <span className="text-sm text-gray-500">
                      {new Date(relatedScam.created_at).toLocaleDateString()}
                    </span>
                  </div>
                  
                  <h3 className="font-semibold mb-2 group-hover:text-emerald-600">
                    {relatedScam.title}
                  </h3>
                  
                  <div className="flex items-center justify-between text-sm">
                    <span className="text-gray-600 dark:text-gray-400">
                      {relatedScam.category?.name}
                    </span>
                    <span className="flex items-center text-red-600">
                      <Flag className="h-4 w-4 mr-1" />
                      {relatedScam.reports_count}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ScamDetail; 