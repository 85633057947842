import { useState } from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';
import { 
  Shield, AlertTriangle, Phone, CreditCard, MessageCircle, 
  Share2, Lock, Building, ShoppingBag, ChevronRight, CheckCircle 
} from 'lucide-react';

function SafetyTips() {
  const [selectedCategory, setSelectedCategory] = useState('all');

  const categories = [
    { id: 'all', name: 'All Tips', icon: Shield },
    { id: 'banking', name: 'Banking & Finance', icon: CreditCard },
    { id: 'government', name: 'Government Services', icon: Building },
    { id: 'shopping', name: 'Online Shopping', icon: ShoppingBag },
    { id: 'social', name: 'Social Media', icon: Share2 },
    { id: 'personal', name: 'Personal Safety', icon: Lock }
  ];

  const tips = [
    {
      category: 'banking',
      title: 'UAE Bank Security Guidelines',
      description: 'Essential banking safety practices for UAE residents',
      icon: CreditCard,
      color: 'blue',
      steps: [
        'Enable two-factor authentication for all banking apps',
        'Never share OTP codes with anyone, including bank staff',
        'Use official UAE bank apps from authenticated stores only',
        'Set up transaction alerts for immediate notification',
        'Regularly monitor your account for unauthorized transactions'
      ],
      contacts: {
        'Central Bank': '800 CBUAE (800 22823)',
        'Dubai Police': '901'
      }
    },
    {
      category: 'government',
      title: 'Government Services Protection',
      description: 'Stay safe when using UAE government services',
      icon: Building,
      color: 'purple',
      steps: [
        'Only use official .ae government websites',
        'Verify government communications through official channels',
        'Keep your Emirates ID information private',
        'Report suspicious government impersonation attempts',
        'Download official UAE PASS app for government services'
      ]
    },
    {
      category: 'shopping',
      title: 'Safe Online Shopping in UAE',
      description: 'Protect yourself while shopping online',
      icon: ShoppingBag,
      color: 'pink',
      steps: [
        'Shop from verified UAE e-commerce platforms',
        'Check seller ratings and reviews carefully',
        'Use secure payment methods only',
        'Keep proof of all transactions',
        'Be wary of deals that seem too good to be true'
      ]
    },
    {
      category: 'social',
      title: 'Social Media Safety',
      description: 'Protect your online presence in UAE',
      icon: Share2,
      color: 'orange',
      steps: [
        'Enable two-factor authentication on all accounts',
        'Be cautious of friend requests from unknown persons',
        'Never share personal or financial information on social media',
        'Report suspicious profiles to platform authorities',
        'Be wary of investment schemes promoted on social media'
      ]
    },
    {
      category: 'personal',
      title: 'Personal Information Protection',
      description: 'Safeguard your personal data',
      icon: Lock,
      color: 'indigo',
      steps: [
        'Use strong, unique passwords for all accounts',
        'Keep software and apps updated',
        'Be cautious when using public Wi-Fi',
        'Regularly review privacy settings on all platforms',
        'Use VPN services when necessary'
      ]
    }
  ];

  const filteredTips = selectedCategory === 'all' 
    ? tips 
    : tips.filter(tip => tip.category === selectedCategory);

  return (
    <>
      <SEO 
        title="UAE Safety Tips & Guidelines"
        description="Essential safety tips to protect yourself from scams in UAE. Learn how to stay safe online and offline."
        keywords="UAE safety tips, Dubai online safety, Abu Dhabi cybersecurity, scam prevention UAE"
      />

      {/* Hero Section */}
      <div className="bg-gradient-to-r from-gray-900 to-gray-800 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <Shield className="h-16 w-16 text-emerald-500 mx-auto mb-6" />
          <h1 className="text-4xl font-bold text-white mb-4">
            UAE Safety Guidelines
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            Comprehensive security guidelines to protect UAE residents from scams and fraud
          </p>
        </div>
      </div>

      {/* Emergency Contact Banner */}
      <div className="bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="flex items-center mb-4 md:mb-0">
              <Phone className="h-8 w-8 text-red-500 mr-4" />
              <div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Emergency Contacts
                </h3>
                <p className="text-gray-600 dark:text-gray-400">
                  Save these numbers for immediate assistance
                </p>
              </div>
            </div>
            <div className="flex flex-wrap gap-4">
              <div className="px-6 py-2 bg-red-50 dark:bg-red-900/30 rounded-lg border border-red-100 dark:border-red-800">
                <span className="text-sm text-red-600 dark:text-red-400 font-medium">
                  Police: 999
                </span>
              </div>
              <div className="px-6 py-2 bg-blue-50 dark:bg-blue-900/30 rounded-lg border border-blue-100 dark:border-blue-800">
                <span className="text-sm text-blue-600 dark:text-blue-400 font-medium">
                  e-Crime: 901
                </span>
              </div>
              <div className="px-6 py-2 bg-purple-50 dark:bg-purple-900/30 rounded-lg border border-purple-100 dark:border-purple-800">
                <span className="text-sm text-purple-600 dark:text-purple-400 font-medium">
                  Abu Dhabi Police: 8002626
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Category Filter */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-12">
          {categories.map(({ id, name, icon: Icon }) => (
            <button
              key={id}
              onClick={() => setSelectedCategory(id)}
              className={`p-6 rounded-xl flex flex-col items-center transition-all ${
                selectedCategory === id
                  ? 'bg-emerald-600 text-white shadow-lg scale-[1.02]'
                  : 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 border border-gray-200 dark:border-gray-700'
              }`}
            >
              <Icon className={`h-8 w-8 mb-3 ${
                selectedCategory === id 
                  ? 'text-white' 
                  : 'text-emerald-600 dark:text-emerald-400'
              }`} />
              <span className={`text-sm font-medium text-center ${
                selectedCategory === id 
                  ? 'text-white' 
                  : 'text-gray-900 dark:text-gray-100'
              }`}>
                {name}
              </span>
            </button>
          ))}
        </div>

        {/* Tips Grid */}
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {filteredTips.map((tip, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden border border-gray-200 dark:border-gray-700"
            >
              {/* Card Header */}
              <div className="bg-gray-900 p-6 relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-emerald-600/20 to-emerald-600/10" />
                <tip.icon className="h-8 w-8 text-emerald-400 mb-4 relative z-10" />
                <h3 className="text-xl font-semibold text-white mb-2 relative z-10">
                  {tip.title}
                </h3>
                <p className="text-sm text-gray-300 relative z-10">
                  {tip.description}
                </p>
              </div>

              {/* Card Content */}
              <div className="p-6">
                <div className="space-y-4">
                  {tip.steps.map((step, stepIndex) => (
                    <div key={stepIndex} className="flex items-start">
                      <CheckCircle className="h-5 w-5 mr-3 flex-shrink-0 text-emerald-500" />
                      <span className="text-gray-700 dark:text-gray-300 text-sm">
                        {step}
                      </span>
                    </div>
                  ))}
                </div>

                {tip.contacts && (
                  <div className="mt-6 pt-6 border-t border-gray-200 dark:border-gray-700">
                    <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-3">
                      Important Contacts
                    </h4>
                    {Object.entries(tip.contacts).map(([key, value]) => (
                      <div key={key} className="flex items-center justify-between text-sm mb-2">
                        <span className="text-gray-600 dark:text-gray-400">{key}</span>
                        <span className="font-medium text-emerald-600 dark:text-emerald-400">{value}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Report CTA */}
        <div className="mt-16 bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl p-8 text-center">
          <h2 className="text-2xl font-bold text-white mb-4">
            Spotted a Scam?
          </h2>
          <p className="text-gray-300 mb-6 max-w-2xl mx-auto">
            Help protect the UAE community by reporting suspicious activities. Your report could prevent others from becoming victims.
          </p>
          <Link
            to="/report-scam"
            className="inline-flex items-center bg-emerald-500 text-white px-6 py-3 rounded-lg hover:bg-emerald-600 transition-all group"
          >
            <AlertTriangle className="h-5 w-5 mr-2" />
            Report a Scam
            <ChevronRight className="h-5 w-5 ml-2 transform group-hover:translate-x-1 transition-transform" />
          </Link>
        </div>
      </div>
    </>
  );
}

export default SafetyTips; 