import { useState, useEffect } from 'react';
import { useSupabase } from '../../context/SupabaseContext';
import { 
  LineChart, Line, BarChart, Bar, PieChart, Pie, 
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell 
} from 'recharts';
import { Shield, AlertTriangle, Users, CheckCircle, XCircle } from 'lucide-react';

function Analytics() {
  const { supabase } = useSupabase();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalScams: 0,
    totalReports: 0,
    pendingReports: 0,
    scamsThisMonth: 0
  });
  const [timelineData, setTimelineData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const colors = ['#059669', '#DC2626', '#F59E0B', '#3B82F6'];

  useEffect(() => {
    fetchAnalytics();
  }, []);

  async function fetchAnalytics() {
    try {
      setLoading(true);

      // Fetch basic stats
      const [scamsCount, reportsCount, pendingCount, monthlyCount] = await Promise.all([
        supabase.from('scams').select('id', { count: 'exact' }),
        supabase.from('reported_scams').select('id', { count: 'exact' }),
        supabase.from('reported_scams').select('id', { count: 'exact' }).eq('status', 'pending'),
        supabase.from('scams')
          .select('id', { count: 'exact' })
          .gte('created_at', new Date(new Date().setDate(1)).toISOString())
      ]);

      setStats({
        totalScams: scamsCount.count || 0,
        totalReports: reportsCount.count || 0,
        pendingReports: pendingCount.count || 0,
        scamsThisMonth: monthlyCount.count || 0
      });

      // Fetch timeline data
      const { data: timelineScams } = await supabase
        .from('scams')
        .select('created_at')
        .order('created_at');

      const timeline = processTimelineData(timelineScams || []);
      setTimelineData(timeline);

      // Fetch category distribution
      const { data: categoryScams } = await supabase
        .from('scams')
        .select(`
          category:category_id (
            name
          )
        `);

      const categories = processCategoryData(categoryScams || []);
      setCategoryData(categories);

      // Fetch status distribution
      const { data: statusReports } = await supabase
        .from('reported_scams')
        .select('status');

      const statuses = processStatusData(statusReports || []);
      setStatusData(statuses);

    } catch (error) {
      console.error('Error fetching analytics:', error);
    } finally {
      setLoading(false);
    }
  }

  function processTimelineData(data) {
    const months = {};
    data.forEach(scam => {
      const month = new Date(scam.created_at).toLocaleString('default', { month: 'short' });
      months[month] = (months[month] || 0) + 1;
    });
    return Object.entries(months).map(([month, count]) => ({ month, count }));
  }

  function processCategoryData(data) {
    const categories = {};
    data.forEach(scam => {
      const categoryName = scam.category?.name || 'Uncategorized';
      categories[categoryName] = (categories[categoryName] || 0) + 1;
    });
    return Object.entries(categories).map(([name, value]) => ({ name, value }));
  }

  function processStatusData(data) {
    const statuses = {};
    data.forEach(report => {
      statuses[report.status] = (statuses[report.status] || 0) + 1;
    });
    return Object.entries(statuses).map(([name, value]) => ({ name, value }));
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
        Analytics Dashboard
      </h1>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
          <div className="flex items-center">
            <Shield className="h-8 w-8 text-emerald-600 mr-3" />
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-400">Total Scams</p>
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                {stats.totalScams}
              </h3>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
          <div className="flex items-center">
            <AlertTriangle className="h-8 w-8 text-yellow-500 mr-3" />
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-400">Total Reports</p>
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                {stats.totalReports}
              </h3>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
          <div className="flex items-center">
            <Users className="h-8 w-8 text-blue-500 mr-3" />
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-400">Pending Reports</p>
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                {stats.pendingReports}
              </h3>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
          <div className="flex items-center">
            <CheckCircle className="h-8 w-8 text-emerald-600 mr-3" />
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-400">This Month</p>
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                {stats.scamsThisMonth}
              </h3>
            </div>
          </div>
        </div>
      </div>

      {/* Charts Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Timeline Chart */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
          <h3 className="text-lg font-semibold mb-6">Scams Timeline</h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={timelineData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#059669" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Category Distribution */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
          <h3 className="text-lg font-semibold mb-6">Category Distribution</h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={categoryData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label
                >
                  {categoryData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Report Status Distribution */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
          <h3 className="text-lg font-semibold mb-6">Report Status Distribution</h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={statusData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#059669">
                  {statusData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics; 