import { useState, useEffect } from 'react';
import { useSupabase } from '../context/SupabaseContext';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';
import { Calendar, Tag, User, Clock, Search, Filter, ChevronRight } from 'lucide-react';

function Blog() {
  const { supabase } = useSupabase();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [featuredPost, setFeaturedPost] = useState(null);

  useEffect(() => {
    fetchPosts();
  }, [selectedCategory, searchTerm]);

  async function fetchPosts() {
    try {
      setLoading(true);
      let query = supabase
        .from('blog_posts')
        .select(`
          *,
          category:category_id (name),
          author:author_id (name, avatar_url)
        `)
        .order('created_at', { ascending: false });

      if (selectedCategory !== 'all') {
        query = query.eq('category_id', selectedCategory);
      }

      if (searchTerm) {
        query = query.or(`title.ilike.%${searchTerm}%,content.ilike.%${searchTerm}%`);
      }

      const { data } = await query;

      // Set featured post and remaining posts
      if (data && data.length > 0) {
        setFeaturedPost(data[0]);
        setPosts(data.slice(1));
      }

      // Fetch categories
      const { data: categoriesData } = await supabase
        .from('blog_categories')
        .select('*')
        .order('name');

      setCategories(categoriesData || []);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  }

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-AE', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const readingTime = (content) => {
    const wordsPerMinute = 200;
    const words = content.split(/\s+/).length;
    return Math.ceil(words / wordsPerMinute);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-600"></div>
      </div>
    );
  }

  return (
    <>
      <SEO 
        title="UAE Scam Prevention Blog"
        description="Latest insights, news, and tips about scam prevention in UAE. Stay informed about the latest fraud trends and protection strategies."
        keywords="UAE scam news, Dubai fraud prevention blog, Abu Dhabi cybersecurity tips"
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Scam Prevention Blog
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Latest insights and updates about scam prevention in UAE
          </p>
        </div>

        {/* Search and Filter */}
        <div className="flex flex-col md:flex-row gap-4 mb-12">
          <div className="flex-1">
            <div className="relative">
              <input
                type="text"
                placeholder="Search articles..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border rounded-lg dark:bg-gray-700"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>

          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="w-full md:w-64 px-4 py-2 border rounded-lg dark:bg-gray-700"
          >
            <option value="all">All Categories</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>{cat.name}</option>
            ))}
          </select>
        </div>

        {/* Featured Post */}
        {featuredPost && (
          <Link
            to={`/blog/${featuredPost.slug}`}
            className="block mb-12 group"
          >
            <div className="relative h-96 rounded-xl overflow-hidden">
              <img
                src={featuredPost.cover_image}
                alt={featuredPost.title}
                className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/75 to-transparent">
                <div className="absolute bottom-0 p-8">
                  <div className="flex items-center space-x-4 mb-4">
                    <span className="px-3 py-1 bg-emerald-600 text-white text-sm rounded-full">
                      Featured
                    </span>
                    <span className="text-emerald-400">
                      {featuredPost.category?.name}
                    </span>
                  </div>
                  <h2 className="text-3xl font-bold text-white mb-4">
                    {featuredPost.title}
                  </h2>
                  <p className="text-gray-200 mb-4 line-clamp-2">
                    {featuredPost.excerpt}
                  </p>
                  <div className="flex items-center text-gray-300 text-sm">
                    <Calendar className="h-4 w-4 mr-2" />
                    {formatDate(featuredPost.created_at)}
                    <Clock className="h-4 w-4 ml-4 mr-2" />
                    {readingTime(featuredPost.content)} min read
                  </div>
                </div>
              </div>
            </div>
          </Link>
        )}

        {/* Posts Grid */}
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {posts.map((post) => (
            <Link
              key={post.id}
              to={`/blog/${post.slug}`}
              className="bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md transition-shadow overflow-hidden group"
            >
              <div className="relative h-48">
                <img
                  src={post.cover_image}
                  alt={post.title}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="p-6">
                <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mb-3">
                  <Tag className="h-4 w-4 mr-2" />
                  {post.category?.name}
                </div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                  {post.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300 mb-4 line-clamp-2">
                  {post.excerpt}
                </p>
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center text-gray-500 dark:text-gray-400">
                    <User className="h-4 w-4 mr-2" />
                    {post.author?.name}
                  </div>
                  <div className="flex items-center text-gray-500 dark:text-gray-400">
                    <Clock className="h-4 w-4 mr-2" />
                    {readingTime(post.content)} min read
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {/* Empty State */}
        {!loading && posts.length === 0 && (
          <div className="text-center py-12 bg-gray-50 dark:bg-gray-800 rounded-xl">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
              No articles found
            </h3>
            <p className="text-gray-600 dark:text-gray-400">
              Try adjusting your search or filter settings
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default Blog; 