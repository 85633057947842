import { ArrowRight, Shield, Search, BookOpen, Bell, UserCheck, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

function Home() {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "WeqayaOnline",
    "url": "https://weqayaonline.com",
    "description": "UAE's premier scam detection and prevention platform"
  };

  return (
    <>
      <SEO 
        title="UAE's Premier Scam Detection Platform"
        description="Protect yourself from online scams in UAE with WeqayaOnline's AI-powered tools."
        keywords="UAE scam detection, Dubai fraud prevention, Abu Dhabi cyber security"
        schema={schema}
      />
      
      {/* Hero Section with Gradient Background */}
      <div className="relative overflow-hidden bg-gradient-to-br from-emerald-500 via-emerald-600 to-emerald-700">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:20px_20px]" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
          <div className="text-center">
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6 leading-tight">
              Stay Safe from Online
              <span className="block">Scams in UAE</span>
            </h1>
            <p className="text-xl text-emerald-100 mb-12 max-w-3xl mx-auto">
              Use our AI-powered tools to verify messages, emails, and protect yourself 
              from the latest scams targeting UAE residents.
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              <Link
                to="/scam-checker"
                className="bg-white text-emerald-600 px-8 py-4 rounded-xl hover:bg-emerald-50 
                  transition-all duration-300 shadow-lg hover:shadow-xl flex items-center"
              >
                Check for Scams <ArrowRight className="ml-2" />
              </Link>
              <Link
                to="/scam-library"
                className="bg-emerald-700 text-white px-8 py-4 rounded-xl hover:bg-emerald-800 
                  transition-all duration-300 shadow-lg hover:shadow-xl"
              >
                Browse Scam Library
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section with Glass Effect */}
      <div className="relative -mt-20 max-w-6xl mx-auto px-4">
        <div className="bg-white/90 backdrop-blur-lg rounded-2xl shadow-xl p-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="text-4xl font-bold text-emerald-600 mb-2">5000+</div>
              <div className="text-gray-600">Scams Detected</div>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-emerald-600 mb-2">10,000+</div>
              <div className="text-gray-600">Users Protected</div>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-emerald-600 mb-2">98%</div>
              <div className="text-gray-600">Accuracy Rate</div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Advanced Protection Features
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Our comprehensive suite of tools helps you stay safe from the latest scams
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {[
            {
              icon: Shield,
              title: "AI-Powered Protection",
              description: "Advanced algorithms detect and analyze potential scams in real-time"
            },
            {
              icon: Search,
              title: "Smart Verification",
              description: "Instantly verify suspicious messages, emails, and websites"
            },
            {
              icon: BookOpen,
              title: "UAE Scam Database",
              description: "Comprehensive library of known scams targeting UAE residents"
            }
          ].map((feature, index) => (
            <div key={index} className="group relative bg-white rounded-2xl shadow-lg p-8 
              hover:shadow-xl transition-all duration-300 overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-br from-emerald-500/0 to-emerald-500/0 
                group-hover:from-emerald-500/5 group-hover:to-emerald-500/10 transition-all duration-500" />
              <feature.icon className="h-12 w-12 text-emerald-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-6">Stay Protected with WeqayaOnline</h2>
          <p className="mb-8 text-lg text-gray-300">
            Join thousands of UAE residents who trust WeqayaOnline for their online safety
          </p>
          <Link
            to="/safety-tips"
            className="bg-emerald-600 text-white px-8 py-4 rounded-xl hover:bg-emerald-700 
              transition-all duration-300 shadow-lg hover:shadow-xl inline-flex items-center"
          >
            Get Safety Tips <ArrowRight className="ml-2" />
          </Link>
        </div>
      </div>
    </>
  );
}

export default Home; 