import { useState, useEffect } from 'react';
import { useSupabase } from '../context/SupabaseContext';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';
import { 
  Shield, AlertTriangle, Search, Filter, Share2, 
  Calendar, TrendingUp, Clock, Tag, ChevronRight 
} from 'lucide-react';

function ScamLibrary() {
  const { supabase } = useSupabase();
  const [scams, setScams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('latest');
  const [featuredScam, setFeaturedScam] = useState(null);

  useEffect(() => {
    fetchData();
  }, [selectedCategory, sortBy, searchTerm]);

  async function fetchData() {
    try {
      setLoading(true);
      // Fetch categories
      const { data: categoriesData } = await supabase
        .from('categories')
        .select('*')
        .order('name');

      setCategories(categoriesData || []);

      // Fetch scams with filters
      let query = supabase
        .from('scams')
        .select(`
          *,
          category:category_id (
            name,
            description
          )
        `);

      if (selectedCategory !== 'all') {
        query = query.eq('category_id', selectedCategory);
      }

      if (searchTerm) {
        query = query.or(`title.ilike.%${searchTerm}%,description.ilike.%${searchTerm}%`);
      }

      switch (sortBy) {
        case 'latest':
          query = query.order('created_at', { ascending: false });
          break;
        case 'most_reported':
          query = query.order('reports_count', { ascending: false });
          break;
        case 'high_risk':
          query = query.order('warning_level', { ascending: false });
          break;
        default:
          query = query.order('created_at', { ascending: false });
      }

      const { data: scamsData } = await query;
      
      // Set featured scam and remaining scams
      if (scamsData && scamsData.length > 0) {
        setFeaturedScam(scamsData[0]);
        setScams(scamsData.slice(1));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }

  const getWarningLevelStyle = (level) => {
    const styles = {
      low: 'bg-green-100 text-green-800 border-green-200',
      medium: 'bg-yellow-100 text-yellow-800 border-yellow-200',
      high: 'bg-orange-100 text-orange-800 border-orange-200',
      critical: 'bg-red-100 text-red-800 border-red-200'
    };
    return styles[level] || styles.medium;
  };

  const schema = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": scams.map((scam, index) => ({
      "@type": "Article",
      "position": index + 1,
      "url": `https://weqayaonline.com/scam-library/${scam.id}`,
      "name": scam.title,
      "description": scam.description,
      "datePublished": scam.created_at
    }))
  };

  return (
    <>
      <SEO 
        title="UAE Scam Database - Latest Fraud Alerts & Warnings"
        description="Browse our comprehensive database of scams targeting UAE residents. Stay informed about the latest fraud attempts and protect yourself."
        keywords="UAE scams database, Dubai fraud alerts, Abu Dhabi scam warnings, online fraud UAE"
        schema={schema}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header Section */}
        <div className="text-center mb-12">
          <div className="inline-block p-3 bg-emerald-100 dark:bg-emerald-900/30 rounded-2xl mb-4">
            <Shield className="h-12 w-12 text-emerald-600" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            UAE Scam Database
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            Browse verified scams and fraud attempts targeting UAE residents
          </p>
        </div>

        {/* Featured Scam */}
        {featuredScam && (
          <div className="mb-12">
            <Link
              to={`/scam-library/${featuredScam.id}`}
              className="block group"
            >
              <div className="relative rounded-2xl overflow-hidden bg-gradient-to-br from-emerald-500 to-emerald-700 p-8">
                <div className="absolute inset-0 bg-black/20"></div>
                <div className="relative z-10">
                  <div className="flex items-center space-x-4 mb-4">
                    <span className="px-3 py-1 bg-white/20 text-white text-sm rounded-full">
                      Featured Alert
                    </span>
                    <span className="text-emerald-100">
                      {featuredScam.category?.name}
                    </span>
                  </div>
                  <h2 className="text-3xl font-bold text-white mb-4">
                    {featuredScam.title}
                  </h2>
                  <p className="text-emerald-100 mb-6 line-clamp-2">
                    {featuredScam.description}
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-6 text-emerald-100">
                      <span className="flex items-center">
                        <Calendar className="h-4 w-4 mr-2" />
                        {new Date(featuredScam.created_at).toLocaleDateString('en-AE')}
                      </span>
                      <span className="flex items-center">
                        <AlertTriangle className="h-4 w-4 mr-2" />
                        {featuredScam.reports_count} reports
                      </span>
                    </div>
                    <span className="text-white group-hover:translate-x-2 transition-transform duration-300">
                      Learn More <ChevronRight className="inline-block h-4 w-4 ml-1" />
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        )}

        {/* Filters Section */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 mb-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Search */}
            <div className="relative">
              <input
                type="text"
                placeholder="Search scams..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 dark:border-gray-700 rounded-xl dark:bg-gray-700 focus:ring-2 focus:ring-emerald-500"
              />
              <Search className="absolute left-3 top-3.5 h-5 w-5 text-gray-400" />
            </div>

            {/* Category Filter */}
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="w-full px-4 py-3 border border-gray-200 dark:border-gray-700 rounded-xl dark:bg-gray-700 focus:ring-2 focus:ring-emerald-500"
            >
              <option value="all">All Categories</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>

            {/* Sort By */}
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="w-full px-4 py-3 border border-gray-200 dark:border-gray-700 rounded-xl dark:bg-gray-700 focus:ring-2 focus:ring-emerald-500"
            >
              <option value="latest">Latest First</option>
              <option value="most_reported">Most Reported</option>
              <option value="high_risk">High Risk First</option>
            </select>
          </div>
        </div>

        {/* Stats Bar */}
        <div className="grid grid-cols-3 gap-6 mb-12">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-4 flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">Total Scams</p>
              <p className="text-2xl font-bold text-emerald-600">{scams.length + 1}</p>
            </div>
            <Shield className="h-8 w-8 text-emerald-600/20" />
          </div>
          <div className="bg-white dark:bg-gray-800 rounded-xl p-4 flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">This Month</p>
              <p className="text-2xl font-bold text-emerald-600">
                {scams.filter(s => new Date(s.created_at).getMonth() === new Date().getMonth()).length}
              </p>
            </div>
            <TrendingUp className="h-8 w-8 text-emerald-600/20" />
          </div>
          <div className="bg-white dark:bg-gray-800 rounded-xl p-4 flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400">High Risk</p>
              <p className="text-2xl font-bold text-emerald-600">
                {scams.filter(s => s.warning_level === 'high' || s.warning_level === 'critical').length}
              </p>
            </div>
            <AlertTriangle className="h-8 w-8 text-emerald-600/20" />
          </div>
        </div>

        {/* Scams Grid */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-600 mx-auto"></div>
          </div>
        ) : (
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {scams.map((scam) => (
              <Link
                key={scam.id}
                to={`/scam-library/${scam.id}`}
                className="group bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden border border-gray-100 dark:border-gray-700"
              >
                <div className="p-6">
                  <div className="flex justify-between items-start mb-4">
                    <span className="px-3 py-1 bg-emerald-50 dark:bg-emerald-900/30 text-emerald-600 dark:text-emerald-400 text-sm rounded-full">
                      {scam.category?.name}
                    </span>
                    <span className={`px-3 py-1 text-xs rounded-full ${getWarningLevelStyle(scam.warning_level)}`}>
                      {scam.warning_level.toUpperCase()}
                    </span>
                  </div>

                  <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-3 group-hover:text-emerald-600 dark:group-hover:text-emerald-400 transition-colors">
                    {scam.title}
                  </h2>

                  <p className="text-gray-600 dark:text-gray-300 mb-4 line-clamp-2">
                    {scam.description}
                  </p>

                  <div className="flex items-center justify-between text-sm">
                    <div className="flex items-center space-x-4 text-gray-500 dark:text-gray-400">
                      <span className="flex items-center">
                        <Calendar className="h-4 w-4 mr-2" />
                        {new Date(scam.created_at).toLocaleDateString('en-AE')}
                      </span>
                      <span className="flex items-center text-red-600 dark:text-red-400">
                        <AlertTriangle className="h-4 w-4 mr-1" />
                        {scam.reports_count}
                      </span>
                    </div>
                    <ChevronRight className="h-5 w-5 text-emerald-600 transform group-hover:translate-x-2 transition-transform" />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}

        {/* Empty State */}
        {!loading && scams.length === 0 && (
          <div className="text-center py-12 bg-gray-50 dark:bg-gray-800/50 rounded-xl">
            <Shield className="h-16 w-16 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
              No scams found
            </h3>
            <p className="text-gray-600 dark:text-gray-400">
              Try adjusting your filters or search terms
            </p>
          </div>
        )}

        {/* Report CTA */}
        <div className="mt-12 bg-gradient-to-br from-red-500 to-red-600 rounded-xl p-8 text-center text-white">
          <h2 className="text-2xl font-bold mb-4">
            Spotted a Scam?
          </h2>
          <p className="mb-6 text-red-100">
            Help protect the UAE community by reporting suspicious activities
          </p>
          <Link
            to="/report-scam"
            className="inline-flex items-center bg-white text-red-600 px-6 py-3 rounded-lg hover:bg-red-50 transition-colors"
          >
            <AlertTriangle className="h-5 w-5 mr-2" />
            Report a Scam
          </Link>
        </div>
      </div>
    </>
  );
}

export default ScamLibrary; 