import { useState, useEffect } from 'react';
import { useSupabase } from '../../context/SupabaseContext';
import { CheckCircle, XCircle, AlertTriangle, Eye, Search, Filter } from 'lucide-react';

function Reports() {
  const { supabase } = useSupabase();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedReport, setSelectedReport] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchReports();
  }, [selectedStatus]);

  async function fetchReports() {
    try {
      let query = supabase
        .from('reported_scams')
        .select(`
          *,
          category:category_id (name)
        `)
        .order('created_at', { ascending: false });

      if (selectedStatus !== 'all') {
        query = query.eq('status', selectedStatus);
      }

      if (searchTerm) {
        query = query.or(`title.ilike.%${searchTerm}%,description.ilike.%${searchTerm}%`);
      }

      const { data, error } = await query;
      if (error) throw error;
      setReports(data || []);
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setLoading(false);
    }
  }

  async function handleStatusChange(id, newStatus) {
    try {
      const { error } = await supabase
        .from('reported_scams')
        .update({ status: newStatus })
        .eq('id', id);

      if (error) throw error;
      
      // If approved, add to scams table
      if (newStatus === 'approved' && selectedReport) {
        const { error: scamError } = await supabase
          .from('scams')
          .insert([{
            title: selectedReport.title,
            description: selectedReport.description,
            category_id: selectedReport.category_id,
            warning_level: 'high',
            reports_count: 1,
            evidence_images: selectedReport.evidence_images
          }]);

        if (scamError) throw scamError;
      }

      fetchReports();
      setShowModal(false);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  }

  const ReportModal = ({ report }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-start mb-6">
            <h3 className="text-xl font-semibold">{report.title}</h3>
            <button onClick={() => setShowModal(false)} className="text-gray-500">
              <XCircle className="h-6 w-6" />
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <label className="text-sm font-medium text-gray-500">Category</label>
              <p>{report.category?.name}</p>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-500">Description</label>
              <p className="whitespace-pre-wrap">{report.description}</p>
            </div>

            <div>
              <label className="text-sm font-medium text-gray-500">Reporter Contact</label>
              <p>Email: {report.email || 'Not provided'}</p>
              <p>Phone: {report.phone || 'Not provided'}</p>
            </div>

            {report.evidence_images && report.evidence_images.length > 0 && (
              <div>
                <label className="text-sm font-medium text-gray-500">Evidence</label>
                <div className="grid grid-cols-2 gap-4 mt-2">
                  {report.evidence_images.map((image, index) => (
                    <img
                      key={index}
                      src={`${supabase.storage.from('scam-reports').getPublicUrl(image).data.publicUrl}`}
                      alt={`Evidence ${index + 1}`}
                      className="rounded-lg"
                    />
                  ))}
                </div>
              </div>
            )}

            <div className="flex justify-end space-x-4 mt-6">
              <button
                onClick={() => handleStatusChange(report.id, 'rejected')}
                className="px-4 py-2 bg-red-100 text-red-600 rounded-lg hover:bg-red-200"
              >
                Reject Report
              </button>
              <button
                onClick={() => handleStatusChange(report.id, 'approved')}
                className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700"
              >
                Approve & Add to Database
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
          Reported Scams
        </h1>

        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search reports..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>

          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="pl-3 pr-10 py-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600"
          >
            <option value="all">All Status</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="text-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-600 mx-auto"></div>
        </div>
      ) : (
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-900">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Report Details
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Category
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {reports.map((report) => (
                <tr key={report.id} className="hover:bg-gray-50 dark:hover:bg-gray-700/50">
                  <td className="px-6 py-4">
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                      {report.title}
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      {report.email}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      {report.category?.name}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                      ${report.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
                        report.status === 'approved' ? 'bg-green-100 text-green-800' : 
                        'bg-red-100 text-red-800'}`}
                    >
                      {report.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {new Date(report.created_at).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => {
                        setSelectedReport(report);
                        setShowModal(true);
                      }}
                      className="text-emerald-600 hover:text-emerald-900"
                    >
                      <Eye className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showModal && selectedReport && (
        <ReportModal report={selectedReport} />
      )}
    </div>
  );
}

export default Reports; 