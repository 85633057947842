import { useState } from 'react';

function Users() {
  return (
    <div>
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
        User Management
      </h1>
      <div className="bg-white dark:bg-gray-800 rounded-xl p-6">
        <p>User management coming soon...</p>
      </div>
    </div>
  );
}

export default Users; 