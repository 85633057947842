import { GoogleGenerativeAI } from "@google/generative-ai";

const genAI = new GoogleGenerativeAI('AIzaSyD7x8OwFR-J29mO2ht-ZbV73WEpMZhGIrk');

export async function analyzeScam(content, type) {
  try {
    const model = genAI.getGenerativeModel({ model: "gemini-pro" });

    const prompt = `
      Analyze this ${type} for potential security concerns.
      Content to analyze: "${content}"
      
      Provide a technical analysis focusing on:
      1. Legitimacy indicators
      2. Technical warning signs
      3. Standard security practices
      4. Risk assessment
      
      Format response as JSON:
      {
        "isSafe": boolean indicating if content appears legitimate,
        "confidence": number between 0-100,
        "reasons": array of technical observations,
        "scamType": string describing type of security concern (or null if safe),
        "recommendation": string with security best practices,
        "warningLevel": "low", "medium", "high", or "critical"
      }

      Example:
      {
        "isSafe": false,
        "confidence": 90,
        "reasons": ["Invalid domain structure", "Request for sensitive data"],
        "scamType": "phishing_attempt",
        "recommendation": "Verify through official channels",
        "warningLevel": "high"
      }
    `;

    const result = await model.generateContent(prompt);
    const response = await result.response;
    const text = response.text();
    
    try {
      // Clean the response text
      const cleanedText = text.replace(/```json\n?|\n?```/g, '').trim();
      const analysis = JSON.parse(cleanedText);
      
      // Validate and normalize
      return {
        isSafe: Boolean(analysis.isSafe),
        confidence: Number(analysis.confidence) || 70,
        reasons: Array.isArray(analysis.reasons) ? analysis.reasons : ['Analysis unclear'],
        scamType: analysis.scamType || (analysis.isSafe ? null : 'Potential Security Concern'),
        recommendation: analysis.recommendation || 'Verify through official channels',
        warningLevel: ['low', 'medium', 'high', 'critical'].includes(analysis.warningLevel) 
          ? analysis.warningLevel 
          : 'medium'
      };
    } catch (parseError) {
      console.error('Error parsing response:', parseError);
      console.log('Raw response:', text);
      
      // Extract information from text
      const isSafe = text.toLowerCase().includes('legitimate') && !text.toLowerCase().includes('not legitimate');
      const containsWarning = text.toLowerCase().includes('warning') || text.toLowerCase().includes('risk');
      
      return {
        isSafe: !containsWarning && isSafe,
        confidence: 70,
        reasons: [text.split('\n')[0] || 'Technical analysis inconclusive'],
        scamType: containsWarning ? 'Potential Security Concern' : null,
        recommendation: 'Please verify through official channels',
        warningLevel: containsWarning ? 'high' : 'medium'
      };
    }
  } catch (error) {
    console.error('Analysis Error:', error);
    return {
      isSafe: false,
      confidence: 100,
      reasons: ['Unable to complete security analysis. Please try again.'],
      scamType: 'Analysis Error',
      recommendation: 'Unable to analyze content. Please try again or contact support.',
      warningLevel: 'high'
    };
  }
} 