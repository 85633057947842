import { useState, useEffect } from 'react';
import { useSupabase } from '../../context/SupabaseContext';
import { 
  AlertTriangle, 
  Users, 
  CheckCircle, 
  XCircle,
  TrendingUp 
} from 'lucide-react';

function Dashboard() {
  const { supabase } = useSupabase();
  const [stats, setStats] = useState({
    totalReports: 0,
    pendingReports: 0,
    totalUsers: 0,
    scamsDetected: 0
  });
  const [recentReports, setRecentReports] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  async function fetchDashboardData() {
    try {
      // Fetch stats
      const { data: reportStats } = await supabase
        .from('reported_scams')
        .select('status', { count: 'exact' });

      const { data: userCount } = await supabase
        .from('profiles')
        .select('id', { count: 'exact' });

      const { data: scamChecks } = await supabase
        .from('scam_checks')
        .select('result', { count: 'exact' });

      // Fetch recent reports
      const { data: recent } = await supabase
        .from('reported_scams')
        .select(`
          *,
          category:category_id (name)
        `)
        .order('created_at', { ascending: false })
        .limit(5);

      setStats({
        totalReports: reportStats?.length || 0,
        pendingReports: reportStats?.filter(r => r.status === 'pending').length || 0,
        totalUsers: userCount?.length || 0,
        scamsDetected: scamChecks?.filter(c => c.result === 'scam').length || 0
      });

      setRecentReports(recent || []);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
        Dashboard Overview
      </h1>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
          <div className="flex items-center">
            <AlertTriangle className="h-8 w-8 text-emerald-600 mr-3" />
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-400">Total Reports</p>
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                {stats.totalReports}
              </h3>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
          <div className="flex items-center">
            <Users className="h-8 w-8 text-emerald-600 mr-3" />
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-400">Total Users</p>
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                {stats.totalUsers}
              </h3>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
          <div className="flex items-center">
            <TrendingUp className="h-8 w-8 text-emerald-600 mr-3" />
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-400">Scams Detected</p>
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                {stats.scamsDetected}
              </h3>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
          <div className="flex items-center">
            <AlertTriangle className="h-8 w-8 text-yellow-500 mr-3" />
            <div>
              <p className="text-sm text-gray-600 dark:text-gray-400">Pending Reports</p>
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white">
                {stats.pendingReports}
              </h3>
            </div>
          </div>
        </div>
      </div>

      {/* Recent Reports */}
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm">
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
            Recent Reports
          </h2>
        </div>
        <div className="divide-y divide-gray-200 dark:divide-gray-700">
          {recentReports.map((report) => (
            <div key={report.id} className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    {report.title}
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    {report.category?.name}
                  </p>
                </div>
                <div className="flex items-center">
                  {report.status === 'pending' ? (
                    <span className="px-3 py-1 text-xs font-medium text-yellow-700 bg-yellow-100 rounded-full">
                      Pending
                    </span>
                  ) : report.status === 'approved' ? (
                    <CheckCircle className="h-5 w-5 text-green-500" />
                  ) : (
                    <XCircle className="h-5 w-5 text-red-500" />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dashboard; 