import { createContext, useContext, useState } from 'react';
import { supabase } from '../lib/supabase';

// Create context with a default value
const SupabaseContext = createContext({ supabase });

export function SupabaseProvider({ children }) {
  const [client] = useState(() => supabase);

  const value = {
    supabase: client,
  };

  return (
    <SupabaseContext.Provider value={value}>
      {children}
    </SupabaseContext.Provider>
  );
}

export function useSupabase() {
  const context = useContext(SupabaseContext);
  if (!context) {
    throw new Error('useSupabase must be used within a SupabaseProvider');
  }
  return context;
} 