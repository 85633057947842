import { Link } from 'react-router-dom';
import { Shield, Phone, Mail, MapPin, Facebook, Twitter, Linkedin, Instagram } from 'lucide-react';

function Footer() {
  const currentYear = new Date().getFullYear();

  const sections = {
    quickLinks: [
      { name: 'Home', path: '/' },
      { name: 'Scam Checker', path: '/scam-checker' },
      { name: 'Scam Library', path: '/scam-library' },
      { name: 'Safety Tips', path: '/safety-tips' },
      { name: 'Report Scam', path: '/report-scam' },
      { name: 'Blog', path: '/blog' },
    ],
    resources: [
      { name: 'Dubai Police e-Crime', href: 'https://www.dubaipolice.gov.ae' },
      { name: 'UAE Central Bank', href: 'https://www.centralbank.ae' },
      { name: 'Abu Dhabi Police', href: 'https://www.adpolice.gov.ae' },
      { name: 'UAE Pass', href: 'https://selfcare.uaepass.ae' },
    ],
    contact: [
      { icon: Phone, info: 'Emergency: 999' },
      { icon: Phone, info: 'e-Crime: 901' },
      { icon: Mail, info: 'info@weqayaonline.com' },
      { icon: MapPin, info: 'Dubai, United Arab Emirates' },
    ],
    social: [
      { icon: Facebook, name: 'Facebook', href: '#' },
      { icon: Twitter, name: 'Twitter', href: '#' },
      { icon: Linkedin, name: 'LinkedIn', href: '#' },
      { icon: Instagram, name: 'Instagram', href: '#' },
    ]
  };

  return (
    <footer className="bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-800">
      {/* Main Footer */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Brand Section */}
          <div>
            <div className="flex items-center mb-4">
              <Shield className="h-8 w-8 text-emerald-600" />
              <span className="ml-2 text-xl font-bold text-gray-900 dark:text-white">
                WeqayaOnline
              </span>
            </div>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Protecting UAE residents from online scams through AI-powered detection and community awareness.
            </p>
            <div className="flex space-x-4">
              {sections.social.map(({ icon: Icon, name, href }) => (
                <a
                  key={name}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-emerald-600 transition-colors"
                  aria-label={name}
                >
                  <Icon className="h-5 w-5" />
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 dark:text-white uppercase tracking-wider mb-4">
              Quick Links
            </h3>
            <ul className="space-y-3">
              {sections.quickLinks.map(({ name, path }) => (
                <li key={name}>
                  <Link
                    to={path}
                    className="text-gray-600 dark:text-gray-400 hover:text-emerald-600 dark:hover:text-emerald-500 transition-colors"
                  >
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Resources */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 dark:text-white uppercase tracking-wider mb-4">
              Official Resources
            </h3>
            <ul className="space-y-3">
              {sections.resources.map(({ name, href }) => (
                <li key={name}>
                  <a
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 dark:text-gray-400 hover:text-emerald-600 dark:hover:text-emerald-500 transition-colors"
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 dark:text-white uppercase tracking-wider mb-4">
              Contact Information
            </h3>
            <ul className="space-y-3">
              {sections.contact.map(({ icon: Icon, info }) => (
                <li key={info} className="flex items-center">
                  <Icon className="h-5 w-5 text-emerald-600 mr-2" />
                  <span className="text-gray-600 dark:text-gray-400">{info}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-gray-200 dark:border-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="md:flex md:items-center md:justify-between">
            <div className="text-sm text-gray-600 dark:text-gray-400">
              © {currentYear} WeqayaOnline. All rights reserved.
            </div>
            <div className="mt-4 md:mt-0 flex space-x-6">
              <Link
                to="/privacy"
                className="text-sm text-gray-600 dark:text-gray-400 hover:text-emerald-600 dark:hover:text-emerald-500"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms"
                className="text-sm text-gray-600 dark:text-gray-400 hover:text-emerald-600 dark:hover:text-emerald-500"
              >
                Terms of Service
              </Link>
              <Link
                to="/contact"
                className="text-sm text-gray-600 dark:text-gray-400 hover:text-emerald-600 dark:hover:text-emerald-500"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Emergency Banner */}
      <div className="bg-red-50 dark:bg-red-900/20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3">
          <div className="text-center text-sm text-red-800 dark:text-red-200">
            <span className="font-semibold">Emergency?</span> Call 999 for immediate assistance or 901 for e-Crime reporting
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 