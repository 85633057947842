import { useState, useEffect } from 'react';
import { useSupabase } from '../../context/SupabaseContext';
import { PlusCircle, Edit, Trash2, Save, X, Search, Filter } from 'lucide-react';

function Content() {
  const { supabase } = useSupabase();
  const [loading, setLoading] = useState(true);
  const [scams, setScams] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [editingScam, setEditingScam] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newScam, setNewScam] = useState({
    title: '',
    description: '',
    category_id: '',
    warning_level: 'medium'
  });

  useEffect(() => {
    fetchData();
  }, [selectedCategory]);

  async function fetchData() {
    try {
      setLoading(true);
      // Fetch categories
      const { data: categoriesData } = await supabase
        .from('categories')
        .select('*')
        .order('name');

      setCategories(categoriesData || []);

      // Fetch scams with filter
      let query = supabase
        .from('scams')
        .select(`
          *,
          category:category_id (name)
        `)
        .order('created_at', { ascending: false });

      if (selectedCategory !== 'all') {
        query = query.eq('category_id', selectedCategory);
      }

      if (searchTerm) {
        query = query.or(`title.ilike.%${searchTerm}%,description.ilike.%${searchTerm}%`);
      }

      const { data: scamsData } = await query;
      setScams(scamsData || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSave(scam) {
    try {
      const { error } = await supabase
        .from('scams')
        .update(scam)
        .eq('id', scam.id);

      if (error) throw error;
      setEditingScam(null);
      fetchData();
    } catch (error) {
      console.error('Error updating scam:', error);
    }
  }

  async function handleAdd() {
    try {
      const { error } = await supabase
        .from('scams')
        .insert([newScam]);

      if (error) throw error;
      setShowAddModal(false);
      setNewScam({
        title: '',
        description: '',
        category_id: '',
        warning_level: 'medium'
      });
      fetchData();
    } catch (error) {
      console.error('Error adding scam:', error);
    }
  }

  async function handleDelete(id) {
    if (window.confirm('Are you sure you want to delete this scam?')) {
      try {
        const { error } = await supabase
          .from('scams')
          .delete()
          .eq('id', id);

        if (error) throw error;
        fetchData();
      } catch (error) {
        console.error('Error deleting scam:', error);
      }
    }
  }

  const ScamModal = ({ scam, onSave, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl max-w-2xl w-full">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold">
              {scam.id ? 'Edit Scam' : 'Add New Scam'}
            </h3>
            <button onClick={onClose} className="text-gray-500">
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-2">Title</label>
              <input
                type="text"
                value={scam.title}
                onChange={(e) => onSave({ ...scam, title: e.target.value })}
                className="w-full px-4 py-2 border rounded-lg dark:bg-gray-700"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Category</label>
              <select
                value={scam.category_id}
                onChange={(e) => onSave({ ...scam, category_id: e.target.value })}
                className="w-full px-4 py-2 border rounded-lg dark:bg-gray-700"
              >
                <option value="">Select Category</option>
                {categories.map(cat => (
                  <option key={cat.id} value={cat.id}>{cat.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Warning Level</label>
              <select
                value={scam.warning_level}
                onChange={(e) => onSave({ ...scam, warning_level: e.target.value })}
                className="w-full px-4 py-2 border rounded-lg dark:bg-gray-700"
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
                <option value="critical">Critical</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Description</label>
              <textarea
                value={scam.description}
                onChange={(e) => onSave({ ...scam, description: e.target.value })}
                rows="6"
                className="w-full px-4 py-2 border rounded-lg dark:bg-gray-700"
              />
            </div>
          </div>

          <div className="flex justify-end mt-6">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 mr-2"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                scam.id ? handleSave(scam) : handleAdd();
                onClose();
              }}
              className="px-4 py-2 bg-emerald-600 text-white rounded-lg"
            >
              {scam.id ? 'Save Changes' : 'Add Scam'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
          Content Management
        </h1>

        <button
          onClick={() => setShowAddModal(true)}
          className="bg-emerald-600 text-white px-4 py-2 rounded-lg flex items-center"
        >
          <PlusCircle className="h-5 w-5 mr-2" />
          Add New Scam
        </button>
      </div>

      {/* Filters */}
      <div className="flex flex-col md:flex-row gap-4 mb-8">
        <div className="flex-1">
          <div className="relative">
            <input
              type="text"
              placeholder="Search scams..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg dark:bg-gray-700"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>

        <div className="flex-1">
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg dark:bg-gray-700"
          >
            <option value="all">All Categories</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>{cat.name}</option>
            ))}
          </select>
        </div>
      </div>

      {/* Content Table */}
      {loading ? (
        <div className="text-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-600 mx-auto"></div>
        </div>
      ) : (
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-900">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Title
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Category
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Warning Level
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date Added
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {scams.map((scam) => (
                <tr key={scam.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                      {scam.title}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm text-gray-900 dark:text-white">
                      {scam.category?.name}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 text-xs font-medium rounded-full
                      ${scam.warning_level === 'low' ? 'bg-green-100 text-green-800' :
                        scam.warning_level === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                        scam.warning_level === 'high' ? 'bg-orange-100 text-orange-800' :
                        'bg-red-100 text-red-800'}`}
                    >
                      {scam.warning_level}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(scam.created_at).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => setEditingScam(scam)}
                      className="text-emerald-600 hover:text-emerald-900 mr-3"
                    >
                      <Edit className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(scam.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Edit Modal */}
      {editingScam && (
        <ScamModal
          scam={editingScam}
          onSave={setEditingScam}
          onClose={() => setEditingScam(null)}
        />
      )}

      {/* Add Modal */}
      {showAddModal && (
        <ScamModal
          scam={newScam}
          onSave={setNewScam}
          onClose={() => setShowAddModal(false)}
        />
      )}
    </div>
  );
}

export default Content; 